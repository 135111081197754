/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

$(document).ready(function () {

    $('[data-toggle="popover"]').popover();

    $('.dropdown-toggle').dropdownHover();

    $('.dropdown-menu').find('form').click(function (e) {
        e.stopPropagation();
    });

    $('.equal-height').responsiveEqualHeightGrid();

    $('.bg-image').each(function (index, el) {
        var url = $(el).attr("data-image");
        if (url) {
            $(el).css("background-image", "url(" + url + ")");
        }
    });


    $('.slick-slider.slider-workshops').slick({
        adaptiveHeight: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 8000,
        infinite: true,
        easing: 'linear',
        useCSS: true,
        speed: 500,
        pauseOnHover: false
    });

    $('.slick-slider.slider-profesores').slick({
        adaptiveHeight: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        speed: 500,
        easing: 'linear',
        useCSS: true,
        infinite: true,
        pauseOnHover: false
    });

    $('.slick-slider.slider-noticias').slick({
        adaptiveHeight: true,
        arrows: true,
        autoplay: false,
        autoplaySpeed: 8000,
        appendArrows: 'div.noticia',
        prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="previous"><span class="glyphicon glyphicon-menu-left"></span></button>',
        nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="next"><span class="glyphicon glyphicon-menu-right"></span></button>',
        infinite: false,
        easing: 'linear',
        useCSS: true,
        speed: 500,
        pauseOnHover: true
    });

    // carousels home
    $('.slider-profesores').owlCarousel({
        items: 1,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        dots: false,
        loop: true,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true
    });

    $('.slider-noticias').owlCarousel({
        items: 1,
        dots: false,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true
    });

    $('.slider-workshops').owlCarousel({
        items: 1,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        dots: false,
        loop: true,
        autoplay: true,
        autoplayTimeout: 6000,
        autoplayHoverPause: true
    });

    $('.slider-muestras').owlCarousel({
        items: 1,
        dots: false,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true
    });


    // noticias previous slide button

    $(document).on('mouseenter', "div.slick-current > div.col-sm-12.height-full > div.col-sm-12 > div.thumbnail.noticia", function () {
        $('div.slick-current > div.col-sm-12.height-full > div.col-sm-12 > div.thumbnail.noticia > button.slick-prev').show();
    });

    $(document).on('mouseleave', "div.slick-current > div.col-sm-12.height-full > div.col-sm-12 > div.thumbnail.noticia", function () {
        $('div.slick-current > div.col-sm-12.height-full > div.col-sm-12 > div.thumbnail.noticia > button.slick-prev').hide();
    });

    $(document).on("click", "div.slick-current > div.col-sm-12.height-full > div.col-sm-12 > div.thumbnail.noticia > button.slick-prev", function () {
        $('.slick-slider.slider-noticias').slick('slickPrev');
    });

    // noticias next slide button

    $(document).on('mouseenter', "div.slick-current > div.col-sm-12.height-full > div.col-sm-12 > div.thumbnail.noticia", function () {
        $('div.slick-current > div.col-sm-12.height-full > div.col-sm-12 > div.thumbnail.noticia > button.slick-next').show();
    });

    $(document).on('mouseleave', "div.slick-current > div.col-sm-12.height-full > div.col-sm-12 > div.thumbnail.noticia", function () {
        $('div.slick-current > div.col-sm-12.height-full > div.col-sm-12 > div.thumbnail.noticia > button.slick-next').hide();
    });

    $(document).on("click", "div.slick-current > div.col-sm-12.height-full > div.col-sm-12 > div.thumbnail.noticia > button.slick-next", function () {
        $('.slick-slider.slider-noticias').slick('slickNext');
    });

    $('.slick-slider.slider-workshops').on('afterChange', function (event, slick, currentSlide, nextSlide) {

        //$('.slider-workshops-wrapper').css({"background" : "linear-gradient(135deg, white 20px, " + color + "1px, " + color + ")"});
    });


    $('.custom-scrollbar-y').perfectScrollbar({
        suppressScrollX: true
    });

    // profesores rollover

    $(document).on('mouseenter', '.profesor', function () {
        $(this).find('.info-basic').toggle(false);
        $(this).find('.info-plus').toggle(true);
    });

    $(document).on('mouseleave', '.profesor', function () {
        $(this).find('.info-plus').toggle(false);
        $(this).find('.info-basic').toggle(true);
    });



});